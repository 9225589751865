<template>
    <div id="body" class="fullscreen">
        <div class="cont" id="p02"
			v-if="!result.success"
		>
            <div class="c01">
                <div class="cont-modal">
                    <div class="modal">
                        <div class="logo"><img src="@/assets/img/logo02.png" alt=""></div>
                        <div class="top flex-column">
                            <div class="title">
                                <span class="tp active"><a >아이디 찾기</a></span>
                                <span class="tp"><router-link :to=" {name: 'AuthFindPw'}">비밀번호 찾기</router-link></span>
                            </div>
                        </div>
                        <div class="form">
                            <input id="ip01" type="text" placeholder="가맹점명"
								v-model="item.businessName"
							>
                            <input id="ip02" type="text" placeholder="휴대폰 번호"
								v-model="item.phoneNumber"
							>
                            <div class="ad">
                                <div class="p">입력 시 -을 제외한 숫자만 입력해주세요.</div>
                                <div class="err"
									:class="{ active: error }"> <!-- 입력 오류시 class "active" -->
                                    입력하신 정보와 매칭되는 아이디가 없습니다.
                                </div>
                            </div>
                        </div>
                        <div class="cont-btn">
                            <a @click="post"><div class="btn-type02">아이디 찾기</div></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
		
		<div class="cont" id="p03"
			v-if="result.success"
		>
            <div class="c01">
                <div class="cont-modal">
                    <div class="modal">
                        <div class="logo"><img src="/assets/img/logo02.png" alt=""></div>
                        <div class="sc-result">
                            <div class="title">아이디 찾기 안내</div>
                            <div class="desc">
                                <span class="name">{{ item.userName }}</span>님이 가입하신 아이디는 아래와 같습니다.
                            </div>
                            <div class="id">{{ item.userId }}</div>
                        </div>                        
                        <div class="cont-btn">
                            <router-link :to="'/Login'"><div class="btn-type01">로그인 하러가기</div></router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="copyright">
                Copyright © {{ codes.metaInfo.copyright }} Corp. All Rights Reserved.
            </div>
        </div>
		
	</div>
</template>


<script>
	
	import { Axios } from '@/resources/axios/axios'
	
	export default {
		name: 'AuthLogin'
		,props: ['codes']
		,data: function(){
			return {
				program: {
					not_header: true
				}
				,item: {
					businessNumber: ''
					,ceoName: ''
					,isIndividual: false
					,userId: ''
					,userName: ''
				}
				,error: false
				,result: {
					success: false
				}
			}
		}
		,methods: {
			post: async function(){
				try{
					const result = await Axios({
						method: 'post'
						,url: '/users/find/id'
						,data: this.item
					})
					
					console.log(result)
					if(result.success){
						this.error = false
						this.result.success = true
						this.item.userId = result.data.content.userId
						this.item.userName = result.data.content.userName
					}else{
						this.error = true
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E.message })
				}
			}
		}
		,created: function(){
			this.$emit('setProgram', this.program)
		}
	}
</script>












